<template>
  <div>
    <b-progress :value="valuedata" :max="max" :precision="2" show-progress class="mb-3"></b-progress>
    <b-alert
        v-for="(question, index) in data_questions"
        :key="index"
        show
        variant="light"
        class="alert alert-custom alert-white alert-shadow fade show gutter-b"
      >
        <div class="alert-text">
          <div class="row">
            <p v-if="question.QuestionOptions == '2'"><H1>waktu pengerjaan {{ question.Times }} Menit Soal Ujian {{ question.QuestionNumber }}</H1></p> 
            <h2 v-if="question.QuestionOptions == '1'">Soal {{ question.QuestionNumber }}</h2>
            <div class="col-auto ml-auto">
              <b-button variant="primary" :disabled="selected.length === 0 && question.QuestionOptions !== '2'" @click="goToNextQuestion(question.SerialNumber, question.QuestionID, question.QuestionOptions)">Next</b-button>
            </div>
          </div>
            <hr>
            <div class="d-flex justify-content-center">
            <b-img v-if="question.QuestionOptions == '1'" :src="question.QuestionImage" fluid alt="Responsive image" class="text-center"> <br><br></b-img>
            </div>
          <div class="row d-flex justify-content-center" >
            <b-img v-if="question.QuestionOptions == '2'" :src="question.QuestionImage" fluid-grow alt="Fluid-grow image" class="text-center"><br><br></b-img>
            <p v-if="question.QuestionOptions == '2'" v-html="limitAndBreakText(question.QuestionDescription, 50)"></p>
            <b-form-checkbox-group v-if="question.QuestionOptions == '1'"
              v-model="selected"
              :options="question.OptionsBoxs"
              required=""
              name="flavour-1"
              size="lg" 
              :max="maxSelections"
            ></b-form-checkbox-group>
          </div>
       </div>
    </b-alert>   
    <b-alert v-if="soal == 0"  
        show
        variant="light"
        class="alert alert-custom alert-white alert-shadow fade show gutter-b"><H1>Terimakasih sudah mengikuti ujian ini 😊</H1>       
        <a
          class="btn btn-light-primary font-weight-bold"
          @click="onLogout"
          >Sign Out</a
        >
     </b-alert>
  </div>
</template>

<script>
import Services from "@/core/services/psikologi-api/Services";
import ApiService from "@/core/services/api.service";
import Swal from 'sweetalert2'
import localStorage from "@/core/services/store/localStorage";
import { LOGOUT } from "@/core/services/store/auth.module";

export default {
  data() {
    return {
      selected: [],
      Number: 0,
      valuedata: 0,
      max: 0,
      timerRunning: false,
      remainingTime: 0,
      timerInterval: null,
      data_questions: [],
      soal: 0,
      off: 0,
      maxSelections: 1,
       optionsboxes: [
         { text: 'A', value: 'A' },
         { text: 'B', value: 'B' },
         { text: 'C', value: 'C' },
         { text: 'D', value: 'D' },
         { text: 'E', value: 'E' },
         { text: 'F', value: 'F' }
       ]
    };
  },
  watch: {
    selected(newVal) {
      const Val = newVal.slice(-1);
      if (newVal.length > this.maxSelections) {
    
        this.$nextTick(() => {
          this.selected = Val; 
        });
      }
    },
    remainingTime(newVal) {
      // Update value progress bar
      this.valuedata = newVal;

      // Reset progress bar jika waktu telah habis
      if (newVal <= 0) {
        clearInterval(this.timerInterval);
        this.valuedata = 0;
      }
    }
  },
  methods: {
    startTimer() {
    if (!this.timerRunning && this.remainingTime > 0) { // Tambahkan pengecekan timerRunning
      this.timerRunning = true; // Set timerRunning menjadi true
      // Set interval untuk mengurangi remainingTime setiap detik
      this.timerInterval = setInterval(() => {
        this.remainingTime--;
        if (this.remainingTime === 0) {
          if (localStorage.getLocalStorage("Number") <= 15) {
            localStorage.setLocalStorage('Number', 15);
          } else if (localStorage.getLocalStorage("Number") <= 30) {
            localStorage.setLocalStorage('Number', 30);
          } else if (localStorage.getLocalStorage("Number") <= 44) {
            localStorage.setLocalStorage('Number', 44);
          }  else if (localStorage.getLocalStorage("Number") >= 44) {
            localStorage.setLocalStorage('Number', 100);
          }
          clearInterval(this.timerInterval);
          this.timerRunning = false; 
          this.getMasterQuestions();
        }
      }, 1000);
    }
  },

    stopTimer() {
        clearInterval(this.timerInterval);
    },
    onLogout() {
      localStorage.setLocalStorage('Number', 1);
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },

    limitAndBreakText(text, limit) {
    const words = text.split(" ");
    let result = "";
    let count = 0;

    for (let i = 0; i < words.length; i++) {
      count += words[i].length;

      if (count <= limit) {
        result += words[i] + " ";
      } else {
        result = result.trim() + "<br>";
        count = 0;
        i--; // Re-process the current word in the next iteration
      }
    }

    return result.trim();
  },

  createBox(maxBox){
      var result = []

      var first = "a", last = maxBox.toLowerCase();
      for(var i = first.charCodeAt(0); i <= last.charCodeAt(0); i++) {
        result.push({text: eval("String.fromCharCode(" + i + ")").toUpperCase(), value: eval("String.fromCharCode(" + i + ")").toUpperCase() })
      }
      return result
  },

    getMasterQuestions() {
      return new Promise((resolve, reject) => {
        let contentType = `application/form-data`;
        let number = localStorage.getLocalStorage("Number");
        if (number === null) {
            number = 1;
        }

        Services.GetId(
          ApiService,
          `master/questions/byid`,
          this.$route.params.TypeTestID + "?QuestionID=" + number,
          contentType,
          response => {
            resolve(response.data);
            // Concatenate base URL with QuestionImage
            const baseUrl = 'https://psikologi.user.cloudjkt02.com/';
            this.data_questions = response.data.map(question => ({
              ...question,
              QuestionImage: baseUrl + question.QuestionImage,
              OptionsBoxs: this.createBox(question.MaxAnswersOpt)
            }));

            this.soal = this.data_questions.length;

            const allQuestionKeys = this.data_questions.map(question => question.QuestionKey.split(',')).flat();
            const uniqueQuestionKeys = [...new Set(allQuestionKeys)];
            const countQuestionKeys = uniqueQuestionKeys.length;
             this.maxSelections = countQuestionKeys;
             if(this.data_questions.map(question => question.QuestionOptions) == 2){
              clearInterval(this.timerInterval);
              this.timerRunning = false;
              this.max =  this.data_questions.map(question => question.Times * 60);
              this.remainingTime = this.max;
             }
             
          },
          err => {

          }
        );
      });
    },

    goToNextQuestion(questionNumber, questionID, QuestionOptions) {
      if (QuestionOptions === '2') {
        // Jika QuestionOptions adalah 2, abaikan langkah selanjutnya
        this.startTimer();
        this.Number = parseInt(questionNumber) + 1;
        localStorage.setLocalStorage('Number', parseInt(questionNumber) + 1);
        this.getMasterQuestions();
        
        return;
      }else{
        const formData = new FormData();
        formData.append("question_id", questionID);
        formData.append("participant_id", this.$route.params.ParticipantID);
        formData.append("Answer_Content", this.selected);
        formData.append("questionNumber", this.Number);
        formData.append("token", this.$route.params.TypeTestID);
        let contentType = `application/form-data`;

        Services.PostData(
          ApiService,
          `process/answer/`,
          formData,
          contentType,
          response => {
            if (response.status) {
              this.selected = [];
              this.Number = parseInt(questionNumber) + 1;
              localStorage.setLocalStorage('Number', parseInt(questionNumber) + 1);
              this.getMasterQuestions();
            } else {
              Swal.fire({
                title: "",
                text: response.data.error,
                icon: "info",
                heightAuto: true,
                timer: 1500
              });
            }
          },
          error => {
            console.error('Error during API call:', error);
          }
        );
      }
    },
    loadData() {
      this.getMasterQuestions()
        .then(() => {
          // Lakukan sesuatu setelah data dimuat
        })
        .catch(error => {
          console.error(error);
        });
    },

    handleBackButton(event) {
      window.history.pushState(null, null, window.location.pathname);
    }
  },
  mounted() {

    this.loadData();
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', this.handleBackButton);
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.handleBackButton);
  },
};
</script>

